<template>
  <div class="text-center">
    <h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl">
      Now let's see what you've learned<br />
      about being HIPAA compliant.<br />
    </h2>
    <p class="mt-6 max-w-xl text-lg leading-8 text-indigo-200">
      The following is the assessment portion of your training. You'll have unlimited time to answer
      20 multiple choice questions.
    </p>
    <div class="mt-10 flex items-center justify-center gap-x-6">
      <nuxt-link :to="path" :class="[...primaryClass, ...classObject]">Begin assessment</nuxt-link>
    </div>
  </div>
</template>

<script setup>
import { createSqid } from "~/utils/id";

definePageMeta({
  layout: "questionnaire",
});

const path = ref(`/assessment/${createSqid(0)}`);
const primaryClass = [
  "focus-visible:outline-white",
  "text-indigo-800",
  "shadow-sm",
  "hover:bg-indigo-50",
  "bg-white",
];

const classObject = [
  "focus-visible:outline-2",
  "focus-visible:outline-offset-2",
  "focus-visible:outline",
  "tracking-tight",
  "h-12",
  "px-12",
  "py-2.5",
  "rounded-md",
  "text-center",
  "text-lg",
  "focus-visible:outline-white",
  "text-indigo-800",
  "shadow-sm",
  "focus-visible:outline-white",
  "text-indigo-800",
  "shadow-sm",
  "hover:bg-indigo-50",
  "bg-white",
];
</script>
